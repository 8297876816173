// import Button1 from "../Button";

function Section3() {
  return (
    <div className="about-section section-3">
      <p>
        Currently, I am actively seeking my next role. Let's connect and discuss
        how I can contribute to your projects!
      </p>
    </div>
  );
}

export default Section3;
