import {
  ReactJs,
  Html5,
  CssThree,
  Javascript,
  Nodedotjs,
  Sass,
  Mongodb,
  Postgresql,
  Jquery,
  Bootstrap,
  Rubyonrails,
  Jest,
  Cypress,
  Storybook,
  Redux,
  Express,
  Ruby,
  Postman,
  Googlemaps,
  Canva,
  Git,
  Wix,
  Greensock,
  Nextdotjs
} from "@icons-pack/react-simple-icons";

export const IconsInfoObject = {
  html: { slug: Html5, text: "HTML5", color: "#E34F26" },
  css: { slug: CssThree, text: "CSS3", color: "#1572B6" },
  sass: { slug: Sass, text: "Sass", color: "#CC6699" },
  jq: { slug: Jquery, text: "jQuery", color: "#0769AD" },
  js: { slug: Javascript, text: "JavaScript", color: "#F7DF1E" },
  react: { slug: ReactJs, text: "React", color: "#61DAFB" },
  redux: { slug: Redux, text: "Redux", color: "#764ABC" },
  node: { slug: Nodedotjs, text: "Node.js", color: "#339933" },
  ex: { slug: Express, text: "ExpressJS", color: "#000000" },
  psql: { slug: Postgresql, text: "PostgreSQL", color: "#4169E1" },
  mongo: { slug: Mongodb, text: "MongoDB", color: "#47A248" },
  bootstrap: { slug: Bootstrap, text: "Bootstrap", color: "#7952B3" },
  rubyRails: { slug: Rubyonrails, text: "Ruby on Rails", color: "#CC0000" },
  ruby: { slug: Ruby, text: "Ruby", color: "#CC342D" },
  postman: { slug: Postman, text: "Postman", color: "#FF6C37" },
  maps: { slug: Googlemaps, text: "Google Maps API", color: "#4285F4" },
  git: { slug: Git, text: "Git & GitHub", color: "#F05032" },
  canva: { slug: Canva, text: "Canva", color: "#00C4CC" },
  wix: { slug: Wix, text: "WIX CMS", color: "#0C6EFC" },
  jest: { slug: Jest, text: "Jest", color: "#C21325" },
  cypress: { slug: Cypress, text: "Cypress", color: "#17202C" },
  storybook: { slug: Storybook, text: "Storybook", color: "#FF4785" },
  greensock: { slug: Greensock, text:"Greensock / GSAP", color: "#88CE02"},
  nextjs: { slug: Nextdotjs, text: "NextJs", color: "#000000"}
};